import {ITableColumn} from '../../types';
import {ReactNode} from 'react';

export type AliasDimension = { name: string, value: string }

export enum AliasType {
    USER = 'USER',
    SYSTEM = 'SYSTEM'
}

export enum AliasStatus {
    ENABLED = 'ENABLED',
    DISABLED = 'DISABLED'
}

export type Alias = {
    id: string,
    name?: string,
    description?: string,
    sub?: string,
    createdFromPersona?: boolean,
    aliasType?: AliasType,
    aliasStatus?: string,
    createdByUsername?: string,
    createdBy?: { givenName: string, familyName: string },
    managedByUsername?: string
    managedBy?: { givenName: string, familyName: string },
    created?: string,
    updated?: string,
}


export interface IAliasTableColumn extends ITableColumn {
    render?: (item: Alias) => ReactNode;
}

export type ListAliases = {
    listAliases: { items: Alias[] }
}

export type GetAlias = {
    getAlias: Alias
}

export type CreateAlias = {
    createAlias: Alias
}

export type UpdateAlias = {
    updateAlias: Alias
}

export type DeleteAlias = {
    deleteAlias: string
}
