import {
    deletePersona,
    fetchPersona,
    savePersona,
    updatePersona,
} from '../reducers/recordsSlice';
import {recordSelector} from '../selectors';
import {
    useAppDispatch,
    useAppSelector
} from '../../../hooks/useTypedReactRedux';
import {Persona} from '../types';
import {
    deleteAliasWithName,
    saveAlias
} from "../../aliases/reducers/recordsSlice.ts";
import {Alias} from "../../aliases/types.ts";

export const usePersona = (personaId?: string) => {


    const dispatch = useAppDispatch();

    const {
        record,
        loading,
    } = useAppSelector((state) =>
                           recordSelector(state, personaId ? personaId : ''),
    );

    // FETCH
    const fetchRecord = () => {
        if (typeof personaId === 'undefined') {
            throw new Error('personaId is required parameter');
        }
        dispatch(fetchPersona(personaId));
    };

    // CREATE, UPDATE DELETE
    const createRecord = (values: Persona) => {

        dispatch(saveAlias({
                               name: [values.givenName, values.familyName].join('.')
                                                                          .toLowerCase(),
                               aliasStatus: 'Enabled',
                               createdFromPersona: true,
                           } as Alias))
            .then(
                () => dispatch(savePersona(values))
            );
    };

    const updateRecord = (values: Persona) => {
        if (typeof personaId === 'undefined') {
            throw new Error('personaId is required parameter');
        }

        dispatch(updatePersona({
                                   id: personaId,
                                   record: values,
                               }));
    };

    const deleteRecord = () => {
        if (typeof personaId === 'undefined') {
            throw new Error('personaId is required parameter');
        }

        dispatch(deleteAliasWithName([record.givenName, record.familyName].join('.')))
            .then(
                () => dispatch(deletePersona(personaId)),
            );

    };

    return {
        record,
        loading,
        fetchRecord,
        createRecord,
        updateRecord,
        deleteRecord,
    };
};
